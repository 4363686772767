import { PACKAGE_VERSION } from './version.js';

// 应用程序的名称，用于User-Agent。
export const APP_NAME = 'OA/' + (PACKAGE_VERSION || '0.21');

// API密钥。使用 https://github.com/tinode/chat/tree/master/keygen 生成自己的密钥
export const API_KEY = 'AQAAAAABAADe7k6WyWjJopTGDoeMsGZr';

// 可能连接的主机数组。
export const KNOWN_HOSTS = {hosted: 'm.sslcn.top', local: 'localhost:6060'};

// 默认的主机名和端口。
export const DEFAULT_HOST = KNOWN_HOSTS.hosted;

// 启用控制台日志记录。
export const LOGGING_ENABLED = true;

// 两次按键通知之间的最小时间（毫秒）。
export const KEYPRESS_DELAY = 3_000;
// 发送已读通知之前的延迟（毫秒）。
export const READ_DELAY = 1_500;

// 默认的最短标签长度。与服务器上的值匹配。
export const MIN_TAG_LENGTH = 2;
// 默认的最长标签长度。与服务器上的值匹配。
export const MAX_TAG_LENGTH = 96;
// 默认的最大标签数量。与服务器上的值匹配。
export const MAX_TAG_COUNT = 16;

// 当前用户发起的P2P订阅的访问模式。
export const DEFAULT_P2P_ACCESS_MODE = 'JRWPS';
// 当前用户创建的新群组话题的访问模式。
export const NEW_GRP_ACCESS_MODE = 'JRWPSAO';
// 频道的访问模式。
export const CHANNEL_ACCESS_MODE = 'JR';

// 无访问权限的访问模式。
export const NO_ACCESS_MODE = 'N';

// 桌面和移动设备之间的媒体查询断点（单位：像素）。应与base.css中的@media (max-size: 640px)匹配。
export const MEDIA_BREAKPOINT = 640;
// CSS 'rem' 单位的大小。默认1rem = 10pt = 13px。
export const REM_SIZE = 13;

// 头像图片的大小：当图片尺寸大于这个值或不是正方形时，它将被调整为这个尺寸的正方形。
export const AVATAR_SIZE = 384;

// 通过带内方式发送的头像的最大大小（单位：字节）。更大的头像将通过带外方式（如上传）发送。
export const MAX_AVATAR_BYTES = 4096;

// 在MessagesView中显示的损坏图片的大小。
export const BROKEN_IMAGE_SIZE = 32;

// 视频预览尺寸，当尺寸缺失时使用。
export const NO_DIMENSIONS_VIDEO = 128;

// 每次调用中获取的聊天消息数量。
export const MESSAGES_PAGE = 24;

// 默认的最大带内附件大小（直接包含在消息中），适合大小为256K的消息。当服务器提供的值不可用时使用。实际的二进制附件大小应小于此值，因为base64编码扩展和一些开销，例如1024字节。
// 在生产中，如果需要，可以增加这个限制。同时增加服务器配置中的max_message_size。
export const MAX_INBAND_ATTACHMENT_SIZE = 262_144;

// 默认的绝对最大附件大小，适用于服务器 = 500MB。当服务器提供的值不可用时使用。在生产中增加到500MB。
export const MAX_EXTERN_ATTACHMENT_SIZE = 524_288_000; // 500MB

// 内联发送的图片的最大允许线性尺寸（像素）。较大的图片将被缩小以使较大尺寸适应此大小。在生产中，可以调整到1600或2400。
export const MAX_IMAGE_DIM = 1024;

// 图片预览的线性尺寸：缩小图片以适应此大小进行预览。
export const IMAGE_PREVIEW_DIM = 64;

// 视频截图的线性尺寸：缩小图片以适应此大小进行预览。
export const VIDEO_PREVIEW_DIM = 96;

// 回复引用预览中的图片缩略图尺寸：缩小图片以适应此大小进行预览。
export const IMAGE_THUMBNAIL_DIM = 36;
// 视频缩略图宽度：允许其更宽但不更高。使用IMAGE_THUMBNAIL_DIM作为高度。
export const VIDEO_THUMBNAIL_WIDTH = 48;

// 在话题标题栏中显示的最大在线用户数。其他将隐藏在“+X more”下。
export const MAX_ONLINE_IN_TOPIC = 4;

// 用户名、话题标题和私人评论的最大长度。
export const MAX_TITLE_LENGTH = 60;
// 话题描述的最大长度。
export const MAX_TOPIC_DESCRIPTION_LENGTH = 360;
// 显示视频通话时用户名的最大长度。
export const MAX_PEER_TITLE_LENGTH = 20;

// 聊天列表中消息预览的长度（字符数）。
export const MESSAGE_PREVIEW_LENGTH = 80;

// 引用回复中的引用长度（对于发出的消息）。
export const QUOTED_REPLY_LENGTH = 30;
// 转发消息预览的长度。
export const FORWARDED_PREVIEW_LENGTH = 84;
// 编辑消息时预览的长度。
export const EDIT_PREVIEW_LENGTH = 48;

// 音频录制的最短持续时间（毫秒）。
export const MIN_DURATION = 2_000;
// 音频录制的最长持续时间（毫秒）。
export const MAX_DURATION = 600_000;

// “联系我们”链接。
export const LINK_CONTACT_US = 'mailto:m@sslcn.top';

// 隐私政策链接。
export const LINK_PRIVACY_POLICY = 'https://m.sslcn.top';

// 服务条款链接。
export const LINK_TERMS_OF_SERVICE = 'https://m.sslcn.top';

// 当用户启动P2P话题时，自动订阅不会在第一个消息之前发生。将此选项设置为true以启用即时订阅。
export const IMMEDIATE_P2P_SUBSCRIPTION = false;

// 允许出现在链接中的URL方案（即不允许javascript:方案）。
export const CLICKABLE_URL_SCHEMES = ['http', 'https', 'ftp', 'ftps'];

// 应用程序中QR码的大小。
export const QRCODE_SIZE = 128;
/* This is just a static page to display when no conversation is selected. */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Tinode } from 'tinode-sdk';

import { APP_NAME } from '../config.js';

export default class LogoView extends React.PureComponent {
  render() {
    const version = APP_NAME + '.';
    return (
      <div id="dummy-view">
        <div>
          <h2>欢迎回到协同办公平台</h2>
          {/* 
          <a href="https://Office">
              <img id="logo" alt="logo" src="img/logo.svg" />
          </a> 
          */}
          <p>
            <FormattedMessage id="label_client" defaultMessage="Client:" description="Label for a client version" /> {version}
          </p>
          <p>
            <FormattedMessage id="label_server" defaultMessage="Server:" description="Label for a server version" /> {this.props.serverVersion.split(' ')[0]} ({this.props.serverAddress})
          </p>
        </div>
      </div>
    );
  }
};